var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-chart-box" },
    [
      _c("ModuleTitleBar", { attrs: { title: _vm.title } }),
      _c("div", { staticClass: "chart_box" }, [
        _c("div", { staticClass: "item_box" }, [
          _c("div", { staticClass: "source_name" }, [
            _vm._v(_vm._s(_vm.sourceName[0])),
          ]),
          _c("div", { ref: "BusinessSalesSource", staticClass: "chart" }),
        ]),
        _c("div", { staticClass: "item_box" }, [
          _c("div", { staticClass: "source_name" }, [
            _vm._v(_vm._s(_vm.sourceName[1])),
          ]),
          _c("div", { ref: "BusinessPeopleSource", staticClass: "chart" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }