var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-chart-box" }, [
    _c(
      "div",
      { staticClass: "title-box" },
      [
        _c("ModuleTitleBar", {
          staticStyle: { flex: "1", "margin-right": "12px" },
          attrs: { title: _vm.salesTitle, isReverse: true },
        }),
        _c("ModuleTitleBar", {
          staticStyle: { flex: "1" },
          attrs: { title: _vm.peopleTitle },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "chart_box" }, [
      _c("div", { ref: "BusinessSalesSource", staticClass: "chart" }),
      _c("div", { ref: "BusinessPeopleSource", staticClass: "chart" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }