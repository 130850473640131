//
//
//
//
//
//
//
//
//
//
//
import * as echarts from "echarts";
export default {
  name: 'IndustryContainer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: '15vh'
    },
    width: {
      type: String,
      default: '70%'
    },
    chartType: String,
    option: Object,
    optionLine: Object,
    optionBar: Object
  },
  data: function data() {
    return {
      showIndustryDialog: this.value,
      chartDialog: null
    };
  },
  watch: {
    //监听弹框值
    value: function value(val) {
      this.showIndustryDialog = val; // console.log(val)
    },
    //传值
    showIndustryDialog: function showIndustryDialog(val) {
      this.$emit("input", val);
    },
    //监听dialog值,并变化后展示
    dialogShow: {
      handler: function handler(value) {
        // console.log("option-container",value)
        this.showIndustryDialog = true;
      },
      deep: true
    }
  },
  methods: {
    initDialogCharts: function initDialogCharts() {
      var _this = this;

      setTimeout(function () {
        _this.chartDialog = echarts.init(_this.$refs.dom); // this.chartDialog.resize();

        if (_this.chartType === 'oneBar') {
          _this.chartDialog.setOption(_this.optionBar, true);
        } else if (_this.chartType === 'moreBar') {
          _this.chartDialog.setOption(_this.option, true);
        } else if (_this.chartType === 'line') {
          _this.chartDialog.setOption(_this.optionLine, true);
        }
      }, 100);
    },
    close: function close() {
      this.showIndustryDialog = false;
    }
  }
};