//
//
//
//
//
//
//
//
//
//
//
import * as echarts from "echarts";
export default {
  name: 'ConsumeStickinessBar',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: '15vh'
    },
    width: {
      type: String,
      default: '70%'
    },
    dialogOption: Object
  },
  data: function data() {
    return {
      showStickinessBarDialog: this.value,
      barChartDialog: null
    };
  },
  watch: {
    //监听弹框值
    value: function value(val) {
      this.showStickinessBarDialog = val; // console.log(val)
    },
    //传值
    showStickinessBarDialog: function showStickinessBarDialog(val) {
      this.$emit("input", val);
    },
    //监听dialog值,并变化后展示
    dialogShow: {
      handler: function handler(value) {
        // console.log("option-container",value)
        this.showStickinessBarDialog = true;
      },
      deep: true
    }
  },
  methods: {
    initDialogCharts: function initDialogCharts() {
      var _this = this;

      setTimeout(function () {
        _this.barChartDialog = echarts.init(_this.$refs.dom);

        _this.barChartDialog.setOption(_this.dialogOption);
      }, 100);
    },
    close: function close() {
      this.showStickinessBarDialog = false;
    }
  }
};