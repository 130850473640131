var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-chart-box" },
    [
      _c("ModuleTitleBar", { attrs: { title: _vm.title } }),
      _c("div", { staticClass: "chart" }, [
        _c("div", {
          ref: "BusinessRatio",
          staticStyle: { width: "100%", height: "100%" },
          attrs: { id: "BusinessRatio" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }