var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-chart-box" },
    [_c("ModuleTitleBar", { attrs: { title: _vm.title } }), _vm._m(0)],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chart" }, [
      _c("div", { staticClass: "circle", attrs: { id: "main1" } }),
      _c("div", { staticClass: "circle", attrs: { id: "main2" } }),
      _c("div", { staticClass: "circle", attrs: { id: "main3" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }