import _slicedToArray from "/home/jenkins/agent/workspace/digitalnmn8f/digital-web/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RouterMenu from '@/layout/components/RouterMenu';
import ConsumeRanking from '@/views/home/components/ConsumeRanking';
import BusinessRatio from '@/views/home/components/BusinessRatio';
import ConsumeRatio from '@/views/home/components/ConsumeRatio';
import ConsumeStickiness from '@/views/home/components/ConsumeStickiness';
import ConversionRate from '@/views/home/components/ConversionRate';
import HomeMap from '@/views/home/components/HomeMap';
import HomeSales from '@/views/home/components/HomeSales';
import TypeRatio from '@/views/home/components/TypeRatio';
import DateSelect from '@/components/commons/DateSelect';
import dayjs from 'dayjs';
import ConsumeStickinessBar from "@/components/commons/container/home/ConsumeStickinessBar";
import HomeSalesContainer from "@/components/commons/container/home/HomeSalesContainer";
import ConsumeRankingContainer from "@/components/commons/container/home/ConsumeRankingContainer";
import ConversionRateContainer from "@/components/commons/container/home/ConversionRateContainer";
import TypeRatioContainer from "@/components/commons/container/home/TypeRatioContainer";
import ConsumeStickinessPie from "@/components/commons/container/home/ConsumeStickinessPie";
import BusinessRatioContainer from "@/components/commons/container/home/BusinessRatioContainer";
export default {
  name: 'Home',
  components: {
    BusinessRatioContainer: BusinessRatioContainer,
    ConsumeStickinessPie: ConsumeStickinessPie,
    TypeRatioContainer: TypeRatioContainer,
    ConversionRateContainer: ConversionRateContainer,
    ConsumeRankingContainer: ConsumeRankingContainer,
    HomeSalesContainer: HomeSalesContainer,
    ConsumeStickinessBar: ConsumeStickinessBar,
    DateSelect: DateSelect,
    RouterMenu: RouterMenu,
    ConsumeRanking: ConsumeRanking,
    BusinessRatio: BusinessRatio,
    ConsumeRatio: ConsumeRatio,
    ConsumeStickiness: ConsumeStickiness,
    ConversionRate: ConversionRate,
    HomeMap: HomeMap,
    HomeSales: HomeSales,
    TypeRatio: TypeRatio
  },
  data: function data() {
    return {
      dates: [],
      stickinessContainer: {
        bar: {
          showDialog: false,
          dialogOption: null,
          title: null
        },
        pie: {
          showDialog: false,
          dialogOption: null,
          title: null
        }
      },
      homeSalesContainer: {
        showBarDialog: false,
        dialogOption: null,
        barTitle: null
      },
      consumeRankingContainer: {
        showBarDialog: false,
        dialogOption: null,
        barTitle: null
      },
      conversionRateContainer: {
        showBarDialog: false,
        dialogOption: null,
        barTitle: null
      },
      typeRatioContainer: {
        showBarDialog: false,
        dialogOption: null,
        barTitle: null
      },
      businessRatioContainer: {
        showDialog: false,
        dialogOption: null,
        title: null
      }
    };
  },
  computed: {
    timeline: function timeline() {
      if (this.dates && this.dates.length > 0) {
        var _this$dates = _slicedToArray(this.dates, 2),
            year = _this$dates[0],
            month = _this$dates[1];

        return dayjs("".concat(year, "-").concat(month)).format('YYYY-MM-DD 00:00:00');
      } else {
        return '';
      }
    }
  },
  methods: {
    //接收子组件的值
    stickinessBarApply: function stickinessBarApply(value, title) {
      this.stickinessContainer.bar.title = title;
      this.stickinessContainer.bar.dialogOption = value;
      this.stickinessContainer.bar.showDialog = true;
    },
    stickinessPieApply: function stickinessPieApply(value, title) {
      this.stickinessContainer.pie.title = title;
      this.stickinessContainer.pie.dialogOption = value;
      this.stickinessContainer.pie.showDialog = true;
    },
    homeSalesApply: function homeSalesApply(value, title) {
      this.homeSalesContainer.barTitle = title;
      this.homeSalesContainer.dialogOption = value;
      this.homeSalesContainer.showBarDialog = true;
    },
    consumeRankingApply: function consumeRankingApply(value, title) {
      this.consumeRankingContainer.barTitle = title;
      this.consumeRankingContainer.dialogOption = value;
      this.consumeRankingContainer.showBarDialog = true;
    },
    conversionRateApply: function conversionRateApply(value, title) {
      this.conversionRateContainer.barTitle = title;
      this.conversionRateContainer.dialogOption = value;
      this.conversionRateContainer.showBarDialog = true;
    },
    typeRatioApply: function typeRatioApply(value, title) {
      this.typeRatioContainer.barTitle = title;
      this.typeRatioContainer.dialogOption = value;
      this.typeRatioContainer.showBarDialog = true;
    },
    businessRatioApply: function businessRatioApply(value, title) {
      this.businessRatioContainer.title = title;
      this.businessRatioContainer.dialogOption = value;
      this.businessRatioContainer.showDialog = true;
    }
  },
  mounted: function mounted() {}
};