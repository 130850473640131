import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getBusiness } from '@/api/sys';
export default {
  name: 'BusinessSelect',
  components: {},
  props: {
    pkMode: {
      // 是不是PK模式
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    immediate: {
      // 是否立马触发，默认是触发第一个
      type: Boolean,
      default: false
    },
    ov: {
      type: Array
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data: function data() {
    return {
      tradeAreaList: []
    };
  },
  computed: {
    options: function options() {
      var _this = this;

      var options = [];
      this.tradeAreaList.forEach(function (d) {
        var areaCode = d.areaCode,
            areaName = d.areaName,
            tradeAreaCode = d.tradeAreaCode,
            tradeAreaName = d.tradeAreaName;
        var find = options.find(function (o) {
          return o.value === areaCode;
        });

        if (find) {
          find.children.push({
            value: tradeAreaCode,
            label: tradeAreaName,
            disabled: _this.ov && _this.ov.includes(tradeAreaCode)
          });
        } else {
          options.push({
            value: areaCode,
            label: areaName,
            children: [{
              value: tradeAreaCode,
              label: tradeAreaName,
              disabled: _this.ov && _this.ov.includes(tradeAreaCode)
            }]
          });
        }
      });
      return options;
    }
  },
  methods: {
    fetchList: function fetchList() {
      var _this2 = this;

      getBusiness().then(function (dataList) {
        _this2.tradeAreaList = dataList;

        if (_this2.immediate) {
          // 立马触发
          var area = _this2.options[0];
          var trade = area.children[0];

          _this2.$emit('change', [area.value, trade.value]);
        }
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.fetchList();
  }
};