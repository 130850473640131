var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "el-row",
        { staticStyle: { width: "100%" }, attrs: { gutter: 15 } },
        [
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 7 } },
            [
              _c("BusinessSource", {
                ref: "ref1",
                on: {
                  businessSalesApply: _vm.businessSalesApply,
                  businessPeopleApply: _vm.businessPeopleApply,
                },
              }),
              _c("BusinessMoneyRatio", {
                ref: "ref2",
                on: { moneyRatioApply: _vm.moneyRatioApply },
              }),
              _c("BusinessShareTop5", {
                ref: "ref3",
                on: { shareApply: _vm.shareApply },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 11 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("BusinessMap", { ref: "ref4" })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("BusinessSalesTrend", {
                        ref: "ref5",
                        on: { salesTrendApply: _vm.salesTrendApply },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [_c("BusinessTags", { ref: "ref6" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 6 } },
            [
              _c("BusinessInfo", { ref: "ref7" }),
              _c("BusinessBody", { ref: "ref8" }),
              _c("BusinessMainShop", { ref: "ref9" }),
              _c("BusinessDynamicTrend", {
                ref: "ref10",
                on: { dynamicTrendApply: _vm.dynamicTrendApply },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("BusinessSalesContainer", {
        attrs: {
          "dialog-option": _vm.businessContainer.sales.dialogOption,
          title: _vm.businessContainer.sales.title,
        },
        model: {
          value: _vm.businessContainer.sales.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.businessContainer.sales, "showDialog", $$v)
          },
          expression: "businessContainer.sales.showDialog",
        },
      }),
      _c("BusinessPeopleContainer", {
        attrs: {
          "dialog-option": _vm.businessContainer.people.dialogOption,
          title: _vm.businessContainer.people.title,
        },
        model: {
          value: _vm.businessContainer.people.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.businessContainer.people, "showDialog", $$v)
          },
          expression: "businessContainer.people.showDialog",
        },
      }),
      _c("BusinessMoneyRatioContainer", {
        attrs: {
          "dialog-option": _vm.moneyRatioContainer.dialogOption,
          title: _vm.moneyRatioContainer.title,
        },
        model: {
          value: _vm.moneyRatioContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.moneyRatioContainer, "showDialog", $$v)
          },
          expression: "moneyRatioContainer.showDialog",
        },
      }),
      _c("ShareContainer", {
        attrs: {
          "dialog-option": _vm.shareContainer.dialogOption,
          title: _vm.shareContainer.title,
        },
        model: {
          value: _vm.shareContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.shareContainer, "showDialog", $$v)
          },
          expression: "shareContainer.showDialog",
        },
      }),
      _c("SalesTrendContainer", {
        attrs: {
          "dialog-option": _vm.salesTrendContainer.dialogOption,
          title: _vm.salesTrendContainer.title,
        },
        model: {
          value: _vm.salesTrendContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.salesTrendContainer, "showDialog", $$v)
          },
          expression: "salesTrendContainer.showDialog",
        },
      }),
      _c("DynamicTrendContainer", {
        attrs: {
          "dialog-option": _vm.dynamicTrendContainer.dialogOption,
          title: _vm.dynamicTrendContainer.title,
        },
        model: {
          value: _vm.dynamicTrendContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.dynamicTrendContainer, "showDialog", $$v)
          },
          expression: "dynamicTrendContainer.showDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }