var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c(
        "el-row",
        {
          staticClass: "mt-5",
          staticStyle: { width: "100%" },
          attrs: { gutter: 15 },
        },
        [
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 7 } },
            [
              _c("BusinessSalesTrend", {
                ref: "ref1",
                on: { salesApply: _vm.salesApply },
              }),
              _c("BusinessPeopleTrend", {
                ref: "ref3",
                on: { peopleApply: _vm.peopleApply },
              }),
              _c("BusinessMoneyRatio", {
                ref: "ref2",
                on: { moneyApply: _vm.moneyApply },
              }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 12 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("BusinessMap", { ref: "ref4" })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("BusinessTags", {
                        ref: "ref5",
                        on: { tagsApply: _vm.tagsApply },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "flex flex-col", attrs: { span: 5 } },
            [
              _c("BusinessActive", { ref: "ref6" }),
              _c("BusinessTraffic", { ref: "ref7" }),
              _c("BusinessSource", {
                ref: "ref8",
                on: {
                  sourceApply: _vm.sourceApply,
                  sourceApply2: _vm.sourceApply2,
                },
              }),
              _c("BusinessDynamicShare", {
                ref: "ref9",
                on: {
                  dynamicApply: _vm.dynamicApply,
                  dynamicApply2: _vm.dynamicApply2,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("SalesContainer", {
        attrs: {
          "dialog-option": _vm.salesContainer.dialogOption,
          title: _vm.salesContainer.title,
        },
        model: {
          value: _vm.salesContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.salesContainer, "showDialog", $$v)
          },
          expression: "salesContainer.showDialog",
        },
      }),
      _c("MoneyContainer", {
        attrs: {
          "dialog-option": _vm.moneyContainer.dialogOption,
          title: _vm.moneyContainer.title,
        },
        model: {
          value: _vm.moneyContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.moneyContainer, "showDialog", $$v)
          },
          expression: "moneyContainer.showDialog",
        },
      }),
      _c("PeopleContainer", {
        attrs: {
          "dialog-option": _vm.peopleContainer.dialogOption,
          title: _vm.peopleContainer.title,
        },
        model: {
          value: _vm.peopleContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.peopleContainer, "showDialog", $$v)
          },
          expression: "peopleContainer.showDialog",
        },
      }),
      _c("TagsContainer", {
        attrs: {
          "dialog-option": _vm.tagsContainer.dialogOption,
          title: _vm.tagsContainer.title,
        },
        model: {
          value: _vm.tagsContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.tagsContainer, "showDialog", $$v)
          },
          expression: "tagsContainer.showDialog",
        },
      }),
      _c("SourceContainer", {
        attrs: {
          "dialog-option": _vm.sourceContainer.dialogOption,
          title: _vm.sourceContainer.title,
        },
        model: {
          value: _vm.sourceContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.sourceContainer, "showDialog", $$v)
          },
          expression: "sourceContainer.showDialog",
        },
      }),
      _c("SourceContainer2", {
        attrs: {
          "dialog-option": _vm.sourceContainer2.dialogOption,
          title: _vm.sourceContainer2.title,
        },
        model: {
          value: _vm.sourceContainer2.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.sourceContainer2, "showDialog", $$v)
          },
          expression: "sourceContainer2.showDialog",
        },
      }),
      _c("DynamicContainer", {
        attrs: {
          "dialog-option": _vm.dynamicContainer.dialogOption,
          title: _vm.dynamicContainer.title,
        },
        model: {
          value: _vm.dynamicContainer.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.dynamicContainer, "showDialog", $$v)
          },
          expression: "dynamicContainer.showDialog",
        },
      }),
      _c("DynamicContainer2", {
        attrs: {
          "dialog-option": _vm.dynamicContainer2.dialogOption,
          title: _vm.dynamicContainer2.title,
        },
        model: {
          value: _vm.dynamicContainer2.showDialog,
          callback: function ($$v) {
            _vm.$set(_vm.dynamicContainer2, "showDialog", $$v)
          },
          expression: "dynamicContainer2.showDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }