import "core-js/modules/es.function.name.js";

var echarts = require('echarts');

import tdTheme from './theme.json';
import { off, on } from '@/utils/tool';
echarts.registerTheme('tdTheme', tdTheme); // import './chart.less'

export default {
  props: {
    title: String,
    tooltip: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      currentValue: this.value,
      currentLoading: this.loading,
      dom: null
    };
  },
  watch: {
    value: function value(val) {
      console.log("mixins", val);
      this.currentValue = val;

      if (this.currentValue) {
        this.initChart();
      }
    }
  },
  methods: {
    resize: function resize() {
      this.dom && this.dom.resize();
    },
    initChart: function initChart() {
      var _this = this;

      if (this.dom) {
        this.dom.dispose();
        this.dom = null;
      }

      this.$nextTick(function () {
        _this.dom = echarts.init(_this.$refs.dom);

        _this.dom.setOption(_this.getOption());

        _this.dom.off('click');

        _this.dom.on('click', function (param) {
          console.log("22222", param);

          _this.$emit('on-click', param.name, param.value);
        });

        on(window, 'resize', _this.resize);
      });
    },
    showDialogContainer: function showDialogContainer() {
      this.$emit("areaSalesApply", this.getDiolagOption(), this.title + " Tops");
    }
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.resize);
  }
};