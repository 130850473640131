var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "chart-info-container",
      attrs: {
        width: _vm.width,
        top: _vm.top,
        title: _vm.title,
        visible: _vm.showBusinessRatioDialog,
      },
      on: { open: _vm.initDialogCharts, close: _vm.close },
    },
    [_c("div", { ref: "dom", staticStyle: { height: "100%", width: "100%" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }